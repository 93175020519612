import Flex from '@deprecated-ui/common/Flex';
import device from '@deprecated-ui/_style/device';
import Image from 'global/Image';
import styled from 'styled-components';

const Wrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  padding: 30px;
  position: relative;

  @media ${device.tablet} {
    margin-bottom: 50px;
    padding: 0;
  }
`;

const PhoneFrame = styled(Image)`
  width: 100%;

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptopL} {
    width: 350px;
  }
`;

const Video = styled.video`
  display: none;

  @media ${device.tablet} {
    display: block;
    position: absolute;
    width: 260px;
    transform: translateY(-1px);
  }

  @media ${device.laptopL} {
    width: 303px;
  }
`;

const FallbackImage = styled(Image)`
  display: block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  transform: translateY(-1px);
  width: 70%;

  @media ${device.tablet} {
    display: none;
  }
`;

export { Wrapper, PhoneFrame, Video, FallbackImage };
