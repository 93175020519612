import Flex from '@deprecated-ui/common/Flex';
import { assetPrefix } from 'constants/app';
import { useState } from 'react';
import clsx from 'clsx';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Wrapper } from '../styled';
import { ContentWrapper, Title, Subtitle } from './styled';
import { BrowserBox } from '../uikit';

function Reports() {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <Wrapper align="center">
        <ContentWrapper>
          <div className="big">
            <BrowserBox image={`${assetPrefix}/images/sbnews2.webp`} />
          </div>
          <Flex direction="column" className="small">
            <Title className={clsx('fadeInLeftX', viewed && 'fadeInLeft')}>
              Berita &amp; Laporan dari Sumber Terpercaya
            </Title>
            <Subtitle className={clsx('fadeInLeftX2', viewed && 'fadeInLeft2')}>
              Satu tempat yang praktis untuk baca berita dari berbagai sumber.
            </Subtitle>
          </Flex>
        </ContentWrapper>
      </Wrapper>
    </ReactVisibilitySensor>
  );
}

export default Reports;
