import Text from '@deprecated-ui/common/Text';
import Flex from '@deprecated-ui/common/Flex';
import { IconWrapper as BaseIconWrapper } from '@deprecated-ui/common/Iconmoon';
import color from '@deprecated-ui/_style/color';
import device from '@deprecated-ui/_style/device';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background: ${color.light};
  border: solid ${color.gainsboro};
  border-width: 1px;
  margin-bottom: 10px;
  padding: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    border-width: 2px;
    padding: 10px 20px;
  }
`;

const TextContent = styled(Text).attrs({
  size: '15px',
  mb: '5px',
})`
  b {
    font-family: var(--bold-font);
  }
`;

const IconWrapper = styled(BaseIconWrapper)`
  align-items: center;
  border: solid 1px ${color.midgray};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  margin-right: 10px;
  justify-content: center;
  width: 30px;
`;

export { Wrapper, TextContent, IconWrapper };
