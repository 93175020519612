import Image from 'global/Image';
import { BoxWrapper, TabToolTop, BrowserContent } from './styled';

interface BrowserBoxProps {
  image: string;
}

function BrowserBox({ image }: BrowserBoxProps) {
  return (
    <BoxWrapper>
      <TabToolTop>
        <span className="browser-bullet" />
        <span className="browser-bullet" />
        <span className="browser-bullet" />
      </TabToolTop>
      <BrowserContent style={{ backgroundImage: `url(${image})` }}>
        <Image src={image} alt="browser preview" />
      </BrowserContent>
    </BoxWrapper>
  );
}

export default BrowserBox;
