import { PhoneFrame, Video, FallbackImage, Wrapper } from './styled';

interface PhonePreviewProps {
  videoUrl: string;
  fallbackImage: string;
}

const PhonePreview = (props: PhonePreviewProps) => {
  const { videoUrl, fallbackImage } = props;
  return (
    <Wrapper>
      <PhoneFrame alt="iPhone frame" src="/images/iphone6.webp" />
      <Video id="video" autoPlay loop muted playsInline>
        <source src={videoUrl} />
      </Video>
      <FallbackImage
        className="videofallback"
        src={fallbackImage}
        alt="Your browser does not support the video"
      />
    </Wrapper>
  );
};

export default PhonePreview;
